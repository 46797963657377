const oneTrustLoader = (function () {
  return {
    loadOneTrust: function () {
      const script1 = document.createElement('script');
      script1.src = "https://cdn.cookielaw.org/consent/385835d2-f701-4e3e-a612-12638f48366f/OtAutoBlock.js"
      script1.setAttribute("type", "text/javascript");
      script1.setAttribute("charset", "UTF-8");

      script1.onload = function () {
        setTimeout(function(){
          console.log('OneTrust Loaded');
        }, 3000);
      };

      const script2 = document.createElement('script');
      script2.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      script2.setAttribute("type", "text/javascript");
      script2.setAttribute("charset", "UTF-8");
      script2.setAttribute("data-domain-script", "385835d2-f701-4e3e-a612-12638f48366f");
      script2.onload = function () {
        setTimeout(function(){
          console.log('OneTrust Loaded');
        }, 3000);
      };
      const script = document.createElement('script');
      script.onload = function () {
        loadNotice();
      };
      script.src = "https://privacyportal-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js";
      script.setAttribute("type", "text/javascript");
      script.setAttribute("charset", "UTF-8");
      script.setAttribute("id", "otprivacy-notice-script");

      //***************************************************************************
      //uncomment following 2 lines for Production Scripts only
      var inlineScript = document.createTextNode('settings="eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vcHJpdmFjeXBvcnRhbC5vbmV0cnVzdC5jb20vcmVxdWVzdC92MS9wcml2YWN5Tm90aWNlcy9zdGF0cy92aWV3cyJ9";');
      script.appendChild(inlineScript);
      //***************************************************************************

      let head = document.getElementsByTagName('head')[0];
      let html = document.getElementsByTagName('html')[0];
      html.insertBefore(script, head);
      // html.insertBefore(script1, head);
      // html.insertBefore(script2, head);
      // head.appendChild(script1);
      // head.appendChild(script2);

    }
  }
})();

let idPrivacyNotice1 = 'a6217130-bd9b-4762-951f-dbe067dd9c98';  //First part of Notice ID
let idPrivacyNotice2 = '27b7a8a1-476b-466d-ae6e-fdb89c6131d4';  //Second Part of Notice ID

//***************************************************************************
//Branding Variables
let privacyUrl = 'default';							//privacy-links set to 'default' to not override, format is 'https://www.website.com'
let buFullName = 'default';							//buFN  	set to 'default' to not override
let buShortName = 'default';  		 				//buSN  	set to 'default' to  not override
let buApplicationName = 'Privacy.BNYMellon.com';  					//buCN  	Do not set to 'default', required field
let buContactEmail = 'default';						//buCE  	set to 'default' to not override, format is'mailto:email@bnymellon.com'
let buContactText = 'default'						//buCE  	set to 'default' to not override
let buContactTitle = 'default';						//buCT  	set to 'default' to not override
let buAddressLine01 = 'default';						//buA1  	set to 'default' to not override
let buAddressLine02 = 'default';						//buA2  	set to 'default' to not override

function loadNotice(){
  OneTrust.NoticeApi.Initialized.then(function() {
  OneTrust.NoticeApi.LoadNotices(['https://privacyportal-cdn.onetrust.com/' + idPrivacyNotice1  + '/privacy-notices/'+ idPrivacyNotice2  + '.json'], true); //add language code after true parameter
    setTimeout(function(){
      const elements = document.getElementsByClassName('privacy-links');
      const fNames = document.getElementsByClassName('buFN');
      const sNames = document.getElementsByClassName('buSN');
      const cNames = document.getElementsByClassName('buCN');
      const eNames = document.getElementsByClassName('buCE');
      const tNames = document.getElementsByClassName('buCT');
      const aNames = document.getElementsByClassName('buA1');
      const bNames = document.getElementsByClassName('buA2');
      for (const element of elements) {
        if (privacyUrl !== 'default') {
          element.href = privacyUrl;
        }
      }
      for (const sname of sNames) {
        if (buShortName !== 'default') {
          sname.innerText = buShortName;
        }
      }
      for (const fname of fNames) {
        if (buFullName !== 'default') {
          fname.innerText = buFullName;
        }
      }
      for (const cname of cNames) {
        cname.innerText = buApplicationName;
      }
      for (const ename of eNames) {
        if (buContactText !== 'default') {
          ename.href = buContactEmail
          ename.innerText = buContactText;
        }
      }
      for (const tname of tNames) {
        if (buContactTitle !== 'default') {
          tname.innerText = buContactTitle;
        }
      }
      for (const aname of aNames) {
        if (buAddressLine01 !== 'default') {
          aname.innerText = buAddressLine01;
        }
      }
      for (const bname of bNames) {
        if (buAddressLine02 !== 'default') {
          bname.innerText = buAddressLine02;
        }
      }
    },3000);//End TimeOut
  });
}
